import React, { Component } from 'react';
import ReactMapboxGl, { ZoomControl, Marker, Layer, Source } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Map } from 'mapbox-gl';
import { connectGeoSearch } from 'react-instantsearch-dom';
import { GeoSearchProvided } from 'react-instantsearch-core'
import { LngLat } from "../../types/LngLat";
// import { ReactComponent as MarkerUnselected } from "../../assets/marker.svg";
import { ReactComponent as MarkerSelected } from "../../assets/marker-selected.svg";
// import { ReactComponent as SalonCentricMarker } from "../../assets/salon-centric-location-icon.svg";
import SearchAsMoving from "./SearchAsMoving";
// import { comparePosition } from "../../lib";


interface MapState {
  lat: number,
  lng: number,
  zoom: number,
  markers: LngLat[],
  isUserInteraction: boolean,
  userInteractionEnabled: boolean
  currentStore: LngLat | null,
  sourceData?: any | null
}

interface MapProps {
  currentStore: LngLat | null,
  onClickMarker: (marker: LngLat) => void
}

const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
const profile = 'driving'; // Set the default routing profile
const meters = 24140;

const ReactMap= ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
});

const sourceData = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: []
  },
};

class MapComponent extends Component<GeoSearchProvided & MapProps, MapState> {
  map: any;

  state = {
    lat: -82.5776,
    lng: 27.9709,
    zoom: 9,
    markers: [],
    currentStore: this.props.currentStore || [0, 0],
    isUserInteraction: false,
    userInteractionEnabled: true,
    sourceData,
  };

  constructor(props: GeoSearchProvided & MapProps) {
    super(props);
    this.centerMapOnCoordinates = this.centerMapOnCoordinates.bind(this);
    this.onMapInteraction = this.onMapInteraction.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<GeoSearchProvided & MapProps>) {

    const { hits, currentStore } = nextProps

    if (hits.length && hits !== this.props.hits) {

      const markers: LngLat[] = hits.map(
        ({ _geoloc }: { _geoloc: { lat: number; lng: number } }) => {
          return [_geoloc.lng, _geoloc.lat];
        }
      );

      this.setState({ markers }, () => {
        /* Center the map on the 1st marker*/
        this.centerMapOnCoordinates(markers[0])

        this.getIso().then((res) => {
          this.setState({...this.state, sourceData: {
            type: 'geojson',
            data: res
          }}, ()=> {
            console.log('after update the state', this.state.sourceData)
          })
        });
      })
    }

    if (currentStore && currentStore !== this.props.currentStore) {
      this.setState({ currentStore }, () => {
        /* Center the map on the store*/
        this.centerMapOnCoordinates(this.state.currentStore)
      })
    }

  }

  centerMapOnCoordinates(coordinates: LngLat) {
    if (!this.state.isUserInteraction) {
      const { refine } = this.props;
      this.setState({ isUserInteraction: true }, () => {
        this.map.flyTo({
          essential: false,
          center: coordinates,
          zoom: 7,
        });

        refine({
          northEast: this.map.getBounds().getNorthEast(),
          southWest: this.map.getBounds().getSouthWest(),
        });
      });
    }
  }

  onMapInteraction(map: Map) {
    if (this.state.userInteractionEnabled) {
      const { refine } = this.props;

      refine({
        northEast: map.getBounds().getNorthEast(),
        southWest: map.getBounds().getSouthWest(),
      });
    }
  }

  // Create a function that sets up the Isochrone API query then makes an fetch call
  // async getIso(map: Map) {
  async getIso() {
    let response= {
      type: "FeatureCollection",
      features: []
    }
    // for (const marker of stores.features) {
    for (const marker of this.state.markers) {
      const query = await fetch(
          // `${urlBase}${profile}/${lon},${lat}?contours_minutes=${minutes}&polygons=true&access_token=${mapboxgl.accessToken}`,
          `${urlBase}${profile}/${marker[0]},${marker[1]}?contours_meters=${meters}&polygons=true&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
          { method: 'GET' }
      );
      const data = await query.json();
      // @ts-ignore
      response.features.push(data.features[0])
    }

    // console.log(map.getSource('my-data'), typeof map.getSource('my-data'))
    return response;
  }

  render() {
    return (
      <div className={'h-full w-full relative'}>
        <div className={'h-screen w-screen'}>
          <ReactMap
            ref={(e) => {
              this.map = e?.state.map;
            }}
            style="mapbox://styles/mapbox/light-v10"
            containerStyle={{
              height: '100%',
              width: '100%',
              position: 'relative',
              display: 'block',
              //overflow: 'hidden'
            }}
            onMoveEnd={this.onMapInteraction}
          >
            <>
              {
                this.state.markers.map((marker: LngLat) => (
                    <Marker coordinates={marker} key={marker[0]-marker[1]}>
                      {/*{comparePosition(this.state.currentStore, marker) ? <MarkerSelected /> : <MarkerUnselected />}*/}
                      {/* <SalonCentricMarker  />*/}
                       <MarkerSelected  />
                    </Marker>
                ))
              }
            </>

              <Source id="my-data" geoJsonSource={this.state.sourceData} />
              <Layer type="fill" id="my-layer" sourceId="my-data" paint={{
                'fill-color': '#5a3fc0',
                'fill-opacity': 0.3
              }} />
              <ZoomControl position={'bottom-right'} />
          </ReactMap>
          <SearchAsMoving checked={this.state.userInteractionEnabled} onChange={(userInteractionEnabled) => this.setState({ userInteractionEnabled })} />
        </div>

      </div>
    );
  }
}

// @ts-ignore
export default connectGeoSearch(MapComponent)